@import 'ngx-toastr/toastr';

$seustand-primary-color: #4259EC;
$seustand-accent-color: #0097FF;

.mat-typography, body, h1, h2, h3, h4, h5, h6, p, span, input, mat-label {
  font-family: 'Helvetica', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  color: $seustand-primary-color;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-size {
  width: 100%;
  height: 100%;
}

.default-form-field {
  width: 100%;
  margin-bottom: 4px;
}

.fake-link {
  color: #0097FF;
  text-decoration: underline;
  cursor: pointer;
}

.fake-link:hover {
  text-decoration: none;
}

.internal-icon {
  color: #BBB;
}

.mat-button-toggle{
  background-color: rgba(187, 187, 187, 0.40)!important;
  color: rgba(108, 108, 108, 0.70)!important;
}

.mat-button-toggle-checked {
  background-color: #fff!important;
  border: 2px solid $seustand-primary-color !important;
  border-radius: 4px;
  color: $seustand-primary-color!important;
}

button.mat-mdc-outlined-button {
  border: 1.8px solid $seustand-primary-color !important;
}

.confirm-code-modal {
  * {
    --item-spacing: 19px;
    --item-height: 3em;
    --item-border: none;
    --item-border-bottom: 2px solid #6C6C6C;
    --item-border-has-value: none;
    --item-border-bottom-has-value: 2px solid #0097FF;
    --item-border-focused: none;
    --item-border-bottom-focused: 2px solid #4259EC;
    --item-shadow-focused: none;
    --item-border-radius: 0px;
  }
}

.cancel-btn {
  button.mat-mdc-outlined-button {
    border: 1.8px solid red !important;
  }
}

.line-info-label {
  color: #6c6c6c;
  font-size: 18px;
  font-weight: 500;
}

.line-info {
  color: #6C6C6C;
  font-size: 18px;
  font-weight: 300;
}

.line-info-link {
  color: #0097FF;
  font-size: 18px;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
}

.mat-mdc-table {
  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      color: #4259EC!important;
      font-size: 18px!important;
      font-weight: 500!important;
    }
  }
}

::ng-deep angular-code-input {
  --item-font-weight: 600;
}

.mat-grid-tile-content {
  padding: 0 15px !important;
}