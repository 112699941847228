@use '@angular/material' as mat;
@include mat.core();
@import "~@ng-select/ng-select/themes/material.theme.css";

$custom-typography: mat.define-typography-config(
  $font-family: 'Helvetica, sans-serif'
);

/* For use in src/lib/core/theming/_palette.scss */
$md-seustandprimary: (
    50 : #e8ebfd,
    100 : #c6cdf9,
    200 : #a1acf6,
    300 : #7b8bf2,
    400 : #5e72ef,
    500 : #4259ec,
    600 : #3c51ea,
    700 : #3348e7,
    800 : #2b3ee4,
    900 : #1d2edf,
    A100 : #ffffff,
    A200 : #e1e3ff,
    A400 : #aeb4ff,
    A700 : #959cff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-seustandaccent: (
    50 : #e0f3ff,
    100 : #b3e0ff,
    200 : #80cbff,
    300 : #4db6ff,
    400 : #26a7ff,
    500 : #0097ff,
    600 : #008fff,
    700 : #0084ff,
    800 : #007aff,
    900 : #0069ff,
    A100 : #ffffff,
    A200 : #f2f7ff,
    A400 : #bfd6ff,
    A700 : #a6c5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$my-primary: mat.define-palette($md-seustandprimary, 500);
$my-accent: mat.define-palette($md-seustandaccent, A200, A100, A400);
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  ),
  typography: $custom-typography,
  density: 0,
));

@include mat.core-theme($my-theme);
@include mat.button-theme($my-theme);

